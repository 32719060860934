import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-label" }
const _hoisted_2 = { class: "p-label" }
const _hoisted_3 = { class: "p-hint" }
const _hoisted_4 = { class: "p-label" }
const _hoisted_5 = {
  key: 1,
  class: "_password-box mt-4"
}
const _hoisted_6 = { class: "p-label" }
const _hoisted_7 = { class: "p-label" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "p-label" }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileCard = _resolveComponent("ProfileCard")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ProfileCard, {
      name: $setup.data.name,
      credits: $setup.accountStoreState.data.company.additional_uploads,
      avatar: $setup.accountStoreState.data.avatar,
      tokens: $setup.accountStoreState.data.company.tokens,
      onNewAvatar: $setup.setAvatar
    }, null, 8, ["name", "credits", "avatar", "tokens", "onNewAvatar"]),
    _createElementVNode("form", {
      class: "_form _relative",
      onSubmit: _cache[13] || (_cache[13] = _withModifiers(($event: any) => ($setup.handleSubmit($setup.v$.$invalid, $setup.v$Password.$invalid)), ["prevent"]))
    }, [
      ($setup.isLoading)
        ? (_openBlock(), _createBlock(_component_Loader, {
            key: 0,
            isOverlay: ""
          }))
        : _createCommentVNode("", true),
      _createElementVNode("label", {
        class: _normalizeClass(["p-field mb-4", { 'p-invalid': $setup.v$.name.$invalid && $setup.v$.name.$dirty }])
      }, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('username')), 1),
        _createVNode(_component_InputText, {
          type: "text",
          modelValue: $setup.v$.name.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.name.$model) = $event)),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => ($setup.v$.name.$touch()))
        }, null, 8, ["modelValue"])
      ], 2),
      _createElementVNode("label", {
        class: _normalizeClass(["p-field mb-4", { 'p-invalid': $setup.v$.email.$invalid && $setup.v$.email.$dirty }])
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('email')), 1),
        _createVNode(_component_InputText, {
          type: "email",
          modelValue: $setup.v$.email.$model,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.v$.email.$model) = $event)),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => ($setup.v$.email.$touch()))
        }, null, 8, ["modelValue"]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('profile.this_is_your_login')), 1)
      ], 2),
      _createElementVNode("label", {
        class: _normalizeClass(["p-field mb-4", { 'p-invalid': $setup.v$.phone.$invalid && $setup.v$.phone.$dirty }])
      }, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('profile.phone')), 1),
        _createVNode(_component_InputText, {
          type: "tel",
          modelValue: $setup.v$.phone.$model,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.v$.phone.$model) = $event)),
          onBlur: _cache[5] || (_cache[5] = ($event: any) => ($setup.v$.phone.$touch()))
        }, null, 8, ["modelValue"])
      ], 2),
      _createElementVNode("button", {
        class: "_link",
        type: "button",
        onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.isShow = !$setup.isShow))
      }, _toDisplayString(_ctx.$t($setup.isShow ? 'cancel' : 'profile.change_password')), 1),
      ($setup.isShow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("label", {
              class: _normalizeClass(["p-field mb-4", { 'p-invalid': $setup.v$Password.old_password.$invalid && $setup.v$Password.old_password.$dirty }])
            }, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('old_password')), 1),
              _createVNode(_component_InputText, {
                type: "password",
                modelValue: $setup.v$Password.old_password.$model,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.v$Password.old_password.$model) = $event)),
                onBlur: _cache[8] || (_cache[8] = ($event: any) => ($setup.v$Password.old_password.$touch()))
              }, null, 8, ["modelValue"])
            ], 2),
            _createElementVNode("label", {
              class: _normalizeClass(["p-field mb-4", { 'p-invalid': $setup.v$Password.new_password.$invalid && $setup.v$Password.new_password.$dirty }])
            }, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('new_password')), 1),
              _createVNode(_component_InputText, {
                type: "password",
                modelValue: $setup.v$Password.new_password.$model,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.v$Password.new_password.$model) = $event)),
                onBlur: _cache[10] || (_cache[10] = ($event: any) => ($setup.v$Password.new_password.$touch()))
              }, null, 8, ["modelValue"]),
              ($setup.v$Password.new_password.$invalid && $setup.v$Password.new_password.$dirty && $setup.v$Password.new_password.minLength)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('profile.password_min_length', { number: 6 })), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("label", {
              class: _normalizeClass(["p-field", { 'p-invalid': $setup.v$Password.replay_new_password.$invalid && $setup.v$Password.replay_new_password.$dirty }])
            }, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('replay_new_password')), 1),
              _createVNode(_component_InputText, {
                type: "password",
                modelValue: $setup.v$Password.replay_new_password.$model,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.v$Password.replay_new_password.$model) = $event)),
                onBlur: _cache[12] || (_cache[12] = ($event: any) => ($setup.v$Password.replay_new_password.$touch()))
              }, null, 8, ["modelValue"]),
              (
            $setup.v$Password.replay_new_password.$dirty &&
            $setup.v$Password.new_password.$model &&
            $setup.v$Password.replay_new_password.$model !== $setup.v$Password.new_password.$model
          )
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('profile.password_confirm')), 1))
                : _createCommentVNode("", true)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        class: "p-button-big _full-width mt-5",
        type: "submit",
        disabled: $setup.isLoading
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('save_change')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ], 32)
  ], 64))
}