
import { ref, computed, reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { email, required, sameAs, minLength } from '@vuelidate/validators';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import { Loader } from '../../components/ui';
import { ProfileCard } from '../../components/common';
import { useStores } from '@/store/Stores';

export default {
  components: {
    ProfileCard,
    InputText,
    Button,
    Loader
  },
  setup() {
    const { accountStore, accountStoreState } = useStores();

    const isShow = ref();
    const { t } = useI18n();
    const toast = useToast();

    accountStore.init();

    const password = reactive({
      old_password: '',
      new_password: '',
      replay_new_password: ''
    });

    const rules = reactive({
      email: { required, email },
      name: { required },
      phone: { required }
    });

    const rulesForPassword = reactive({
      old_password: { required },
      new_password: { required, minLength: minLength(6) },
      replay_new_password: { required }
    });

    const data = computed(() => {
      return {
        name: accountStoreState.data.name,
        email: accountStoreState.data.email,
        phone: accountStoreState.data.phone,
        avatar: null as any
      };
    });
    const isLoading = computed(() => accountStoreState.isLoading);

    const v$ = useVuelidate(rules, data);
    const v$Password = useVuelidate(rulesForPassword, password);

    const handleSubmit = (isFormValid, isPasswordValid) => {
      let body = { ...data.value };
      v$.value.$touch();
      if (isShow.value) {
        v$Password.value.$touch();
      }
      if (isFormValid || (isShow.value && isPasswordValid && password.replay_new_password !== password.new_password)) {
        return;
      }
      if (isShow.value) {
        body = { ...body, ...password };
      }

      accountStore.saveChanges(body, toast, t);
    };

    function setAvatar(file) {
      data.value.avatar = file;
    }

    return {
      accountStore,
      accountStoreState,

      v$,
      v$Password,
      isShow,
      password,
      handleSubmit,
      data,
      isLoading,
      setAvatar
    };
  }
};
